
var app = angular.module('app', ['core', 'ngRoute', 'ngResource', 'firebase']);


app.config(['$routeProvider', '$httpProvider', 
    function ($routeProvider, $httpProvider) {

        $routeProvider.
        when('/home', {
            template: '<home></home>'
        }).
        when('/clients', {
            templateUrl: '/app/persons/persons.html',
            controller: 'personsCtrl'
        }).
        when('/suppliers', {
            templateUrl: '/app/persons/persons.html',
            controller: 'personsCtrl'
        }).
        when('/invoces', {
            templateUrl: '/app/invoces/invoces.html',
            controller: 'invocesCtrl'
        }).
        when('/invoces/new', {
            templateUrl: '/app/invoces/new-invoce.html',
            controller: 'newInvoceCtrl'
        }).
        when('/invoces/program', {
            templateUrl: '/app/invoces/program-invoces.html',
            controller: 'programInvoceCtrl'
        }).
        when('/invoces/:id', {
            templateUrl: '/app/invoces/new-invoce.html',
            controller: 'newInvoceCtrl'
        }).
        when('/invoces/clone/:id', {
            templateUrl: '/app/invoces/new-invoce.html',
            controller: 'newInvoceCtrl'
        }).
        when('/products', {
            templateUrl: '/app/products/products.html',
            controller: 'productsCtrl'
        }).
        when('/purchases', {
            templateUrl: '/app/purchases/purchases.html',
            controller: 'purchasesCtrl'
        }).
        when('/purchases/new', {
            templateUrl: '/app/purchases/new-purchase.html',
            controller: 'newPurchaseCtrl'
        }).
        when('/purchases/:id', {
            templateUrl: '/app/purchases/new-purchase.html',
            controller: 'newPurchaseCtrl'
        }).
        when('/purchases/clone/:id', {
            templateUrl: '/app/purchases/new-purchase.html',
            controller: 'newPurchaseCtrl'
        }).
        otherwise({
            redirectTo: '/home'
        });

        $httpProvider.interceptors.push('appInterceptor');
    }]);
