angular.module('app').controller('programInvoceCtrl', ['$scope', 'clientsService', 'invocesService', 'invocesProgramService', 'productsService', function ($scope, clientsService, invocesService, invocesProgramService, productsService) {
    $scope.forCreateExcludes={};
    clientsService.get().$promise.then(function(d){
        for(var i in d){
            d[i].id = i;
        }
        $scope.clients = d;
        loadPrograms();
    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
   function loadPrograms() {
       invocesProgramService.get().$promise.then(function(d){
        d.length = 0;
        for(var i in d){
            if(i.indexOf('-')==0){
                d[i].id = i;
                d.length++;
            }
        }
        $scope.programs = d;
        validatePrograms();
    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
   }
    invocesService.resource.get().$promise.then(function(d){
        var invoces = d;
        $scope.invoces = invoces;
    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });  

    $scope.refreshData = function(){
        if(!$scope.editing) {
                var period = $scope.programPeriods[$scope.program.period];
            //if($scope.program.period == 'month-to-month') {
                if(!$scope.program.start && $scope.program.reference && $scope.program.increment){
                    $scope.program.start=moment($scope.invoces[$scope.program.reference].created)
                    .add($scope.program.increment, period.reserved).toDate();
                    if(!$scope.program.next)
                        $scope.program.next =$scope.program.start;
                }
            //}
        }
    }
    $scope.edit = function(id, p) {
        $scope.program = $.extend({}, p);
        $scope.program.id = id;
        $scope.editing = true;
    }
    $scope.save = function() {
        if($scope.editing) {
            invocesProgramService.edit($scope.program).$promise.then(function(d){
                $scope.editing = false;
                $scope.program = {};
                loadPrograms();
            }, function(){
                alert('Ha ocurrido un error');
            });
        }
        else {
            invocesProgramService.save($scope.program).$promise.then(function(d){
                $scope.program = {};
                loadPrograms();
            }, function(){
                alert('Ha ocurrido un error');
            });
        }
    }
    $scope.delete = () => {
        if(!confirm('Desea eliminar esta programación?'))
            return;
        invocesProgramService.delete($scope.program).$promise.then((response) => {
            delete $scope.programs[$scope.program.id];
            $scope.clear();
        }, () => {
           alert('Ha ocurrido un error');
        });
    };

    $scope.clear = () => {
        $scope.program = null;
        $scope.editing = false;
    };

    function validatePrograms() {
        $scope.forCreate = [];
        for(var i in $scope.programs){
            if ($scope.programs[i].next < new Date()){
                $scope.programs[i].id = i;
                $scope.forCreate.push($.extend({}, $scope.programs[i]));
            }
        }
    }
    $scope.generateStatus = {};

    $scope.generateInvoces = function() {
        $scope.generateGeneralStatus='...';

        //consultar los productos
        productsService.get().$promise.then(function(d){
            $scope.products = d
            generateInvoce(0);
        }, function(){
            $scope.generateGeneralStatus='Ocurrió un error al cargar los productos. Vuelva a intentarlo';
        });
        
    }
    function generateInvoce(idx) {
        if (idx == $scope.forCreate.length)
        {
            $scope.generateGeneralStatus = null;
            alert('Ha finalizado la creación de las facturas.');
            return;
        }
        var invoce = $scope.forCreate[idx];
        if ($scope.forCreateExcludes[invoce.id])
            return generateInvoce(idx + 1);
        if (invoce.recentInvoceId)
            return generateInvoce(idx + 1);
        $scope.generateStatus[invoce.id] = 'generating';

        invocesService.resource.get().$promise.then(function(d){
            var maxNumber = _.max(d, function(inv){ return inv.number; }).number;
            var newInvoce = d[invoce.reference];
            var programUpdated = $.extend({}, $scope.programs[invoce.id]);

            newInvoce.comments = '';
            newInvoce.payed = 0;
            newInvoce.created = invoce.next;
            newInvoce.expireDate = moment(newInvoce.created).add($scope.configuration.daysExpireInvoces, 'days').toDate();
            
            newInvoce.periodStart = invoce.next;
            newInvoce.periodEnd = moment(invoce.next)
                                        .add(programUpdated.increment, $scope.programPeriods[programUpdated.period].reserved)
                                        .add(-1, "days")
                                        .toDate();

            newInvoce.number = maxNumber + 1;
            newInvoce.status = 'Expedida';
            if (newInvoce.id) newInvoce.id = undefined;
            for (var pi in newInvoce.products) {
                var product = newInvoce.products[pi];
                $.extend(product, $scope.products[product.productId]);
            }
            
            invocesService.resource.save(newInvoce).$promise.then(function(d) {                
               
                invoce.recentInvoceId = d.name;
                
                
                
                //actualizar fecha de siguiente creación
                programUpdated.next = moment(programUpdated.next).add(programUpdated.increment, $scope.programPeriods[programUpdated.period].reserved).toDate();
                invocesProgramService.edit(programUpdated).$promise.then(function(d) {
                    $scope.programs[invoce.id].next = programUpdated.next;
                    $scope.generateStatus[invoce.id] = 'ok';
                    return generateInvoce(idx + 1);
                }, function(){
                    alert('Ha ocurrido un error');
                });
            }, function(){
                $scope.generateGeneralStatus = 'Ocurrió un error al guardar una de las facturas';
            });
        }, function(){
            $scope.generateGeneralStatus = 'Ocurrió un error al cargar las facturas. Vuelva a intentarlo';
        }); 
    }
}]);
