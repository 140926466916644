angular.module('app').controller('newInvoceCtrl', ['$scope', '$routeParams', '$location', 'clientsService', 'invocesService', 'productsService', 'notesService', 'authService', function ($scope, $routeParams, $location, clientsService, invocesService, productsService, notesService, authService) {
    
   
    function newInvoce() {
        $scope.invoce = {
            created: new Date(),
            expireDate: new Date(),
            paymentType:"cash",
            status:"Expedida",
            products : []
        };
    }
    if($routeParams.id) {
        if($location.$$path.indexOf('clone') >= 0)
            $scope.cloning = true;
        else $scope.editing = true;
    }
    else newInvoce();

    const fillNotes = () => {
        notesService.get({ invoceId: $scope.invoce.id }).$promise.then(response => {
            console.log('notes', response);
            const notes = [];
            for(let prop in response) {
                if (!response[prop].invoceId) continue;
                notes.push(response[prop]);
            }
            $scope.notes = notes;
        });
    };

    clientsService.get().$promise.then(function(d){
        $scope.clients = d;

        if($scope.editing || $scope.cloning)
        {
            invocesService.resource.get($routeParams).$promise.then(function(d) {
                $scope.invoce = d;
                if ($scope.editing){
                    $scope.invoce.id = $routeParams.id;
                    if($scope.invoce.periodStart)
                        $scope.showPeriod = true;
                }
                if ($scope.cloning) {
                    $scope.invoce.id = null;
                    $scope.invoce.number = null;
                    $scope.invoce.status = 'Expedida';
                    $scope.invoce.payed = null;
                }
                fillNotes();
                $scope.fillSummaries();
            }, function(){
                alert('Ha ocurrido un error al consultar clientes');
            });
        }
        if (!$scope.editing) {
            //hayar próxima factura...
            invocesService.get().then(function(d){
                var maxNumber = _.max(d, function(inv){ return inv.number; }).number;
                $scope.invoce.number = maxNumber + 1;
            }, function(){
                $scope.generateGeneralStatus = 'Ocurrió un error al cargar las facturas. Vuelva a intentarlo';
            }); 
        }

    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
    productsService.get().$promise.then(function(d){
        $scope.products = [];
        for(var i in d){
            var product = d[i];
            if(!product.ref)return;
            product.id = i;
            $scope.products.push(product);
        }
        
    }, function(){
        alert('Ha ocurrido un error al consultar productos');
    });

    $scope.$watch('invoce.status', function(){
        if($scope.invoce && $scope.invoce.status == 'Pagada')
            $scope.invoce.payed =  $scope.summary.total;
    });


    $scope.products = [];
    $scope.fillSummaries = function() {
        $scope.summary = {
           discount: 0,
           summary: 0,
           total: 0
        };
        $scope.summary = $scope.getInvoceSummaries($scope.invoce);
        return;
        for(var i in $scope.invoce.products){
            var thisDiscount = 
            $scope.invoce.products[i].unitPrice * ($scope.invoce.products[i].discount / 100) * $scope.invoce.products[i].quantity;
            $scope.summary.discount+=thisDiscount;
            $scope.summary.summary+=$scope.invoce.products[i].unitPrice * $scope.invoce.products[i].quantity;
            $scope.summary.total = $scope.summary.summary - $scope.summary.discount;
        }

    }
    $scope.productChanged = function(p) {
        var fullP = _.where($scope.products, {id : p.productId})[0];
        $.extend(p, fullP);
        
    }
    $scope.remove = function(p){
        if(confirm('Desea eliminar este producto?'))
            $scope.invoce.products = $scope.invoce.products.filter(function(el){
              return el.$$hashKey !== p.$$hashKey;
            });
    }
    $scope.save = function(){
        if($scope.invoce.products.length == 0)
            return alert('No hay productos');
        if($scope.editing) {
            //var i = {};
            //i[$routeParams.id] = $scope.invoce;
            invocesService.resource.edit($scope.invoce).$promise.then(function(d){
                document.location.href="#!/invoces";
            }, function(){
                alert('Ha ocurrido un error');
            });
        }
        else {
            invocesService.resource.save($scope.invoce).$promise.then(function(d){
                if(d.name){
                    document.location.href="#!/invoces";
                }
            }, function(){
                alert('Ha ocurrido un error');
            });
        }
    }

   

    $scope.addNote = () => {
        let note = {
            invoceId: $scope.invoce.id,
            content: $scope.newNote,
            usedUid: authService.user.uid,
            userName: authService.user.displayName,
            dateTime: new Date()
        };
        notesService.save(note).$promise.then(response => {
            if (response.name) {
                $scope.notes.push(note);
                $scope.newNote = undefined;
            }
            else {
                alert('Ha ocurrido un error');
            }
        }, response => {
            alert('Ha ocurrido un error');
        });
    }
}]);
