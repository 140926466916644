app.controller('productsCtrl', ['$scope', 'productsService', function ($scope, productsService) {
    function load(){
        productsService.get().$promise.then(function(d){
            $scope.products = d;
        }, function(){
            alert('Ha ocurrido un error');
        });
    }
    load();
    $scope.save = function(){
        if ($scope.editing) {
            productsService.edit($scope.product).$promise.then(function(d) {
                if(d.name) {
                    load();
                    $scope.product = null;
                    $scope.editing=false;
                }
            }, function(){
                alert('Ha ocurrido un error');
            }); 
        }
        else {
            productsService.save($scope.product).$promise.then(function(d){
                if(d.name){
                    load();
                    $scope.product = null;
                }
            }, function(){
                alert('Ha ocurrido un error');
            }); 
        }
    }
    $scope.edit = function(id, p) {
        $scope.product = $.extend({}, p);
        $scope.product.id = id;
        $scope.editing = true;
    }
}]);