var baseUrl = 'https://efactura-67a63.firebaseio.com';
// var baseUrl = 'https://efactura-test.firebaseio.com';
app.factory('clientsService', ['$resource', function ($resource) {
  return $resource(baseUrl + '/clients/:id'+'.json', {id: '@id'}, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }}
  });
}]);
app.factory('suppliersService', ['$resource', function ($resource) {
  return $resource(baseUrl + '/suppliers/:id'+'.json', {id: '@id'}, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }}
  });
}]);
app.factory('productsService', ['$resource', function ($resource) {
  return $resource(baseUrl + '/products/:id'+'.json', {id: '@id'}, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }}
  });
}]);

app.factory('purchasesService', ['$resource', function ($resource) {
  return $resource(baseUrl + '/purchases/:id'+'.json', {id: '@id'}, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }}
  });
}]);
app.factory('invocesProgramService', ['$resource', function ($resource) {
  return $resource(baseUrl + '/program/:id'+'.json', { id: '@id' }, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }},
      delete: { method: 'DELETE', params: { includeToken: true }}
  });
}]);
app.factory('notesService', ['$resource', function ($resource) {
  return $resource(baseUrl + '/notes/:invoceId/:id'+'.json', { id: '@id', invoceId: '@invoceId' }, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }}
  });
}]);
app.factory('sammInvocesService', ['$http', function ($http) {
  const getInvoces = () => {
    console.log($http.get('http://localhost:8375/services/sammgpsservice.svc/facturas/c1/2018%2001%2001,2019%2012%2031').$promise);
  };

  return {
    get: getInvoces
  };
}]);
