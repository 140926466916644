'use strict';
(function(){
  const homeComponent = function($q, invocesService, clients2Service, sammInvocesService) {
    $q.all([invocesService.get(), clients2Service.get()]).then(responses => {
      this.invoces = responses[0];
      this.clients = responses[1];

      this.clients.forEach(client => {
        client.pendingInvoces = this.invoces.filter(invoce => 
          invoce.clientId === client.id &&
          invoce.status !== 'Pagada' &&
          invoce.status !== 'Anulada');
        client.pendingAmmount = client.pendingInvoces.reduce((sum, invoce) =>  sum + (invoce.summaries.total - (invoce.payed || 0)), 0);
        client.pendingMinDate = client.pendingInvoces.reduce((minDate, invoce) => minDate ? (minDate > invoce.created ? invoce.created : minDate) : invoce.created, undefined);
        
      });
      this.clientsWithPending = this.clients.filter(client => client.pendingAmmount);

    });

    
    // sammInvocesService.get();
  }
  
  angular.module('app').component('home', {
    templateUrl: 'app/home/home.template.html',
    controller: ['$q', 'invocesService', 'clients2Service', 'sammInvocesService', homeComponent]
  });
})();