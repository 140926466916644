app.controller('purchasesCtrl', ['$scope', 'suppliersService', 'purchasesService', 'productsService', function ($scope, suppliersService, purchasesService, productsService) {
    
    suppliersService.get().$promise.then(function(d){
        for(var i in d){
            d[i].id = i;
        }
        $scope.suppliers = d;

    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
    purchasesService.get().$promise.then(function(d){
        var purchases = [];
        for(var p in d){
            var purchase = d[p];
            if(!purchase.number)continue;
            purchase.summaries = $scope.getInvoceSummaries(purchase);
            if(purchase.status != 'Pagada' && purchase.expireDate <= new Date())
                purchase.expired=true;
            purchase.purchaseId = p;
            
            purchases.push(purchase);
        }
        $scope.purchases = purchases;
    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
    $scope.getFilterTotal = function(){
        var t = 0;
        if(!$scope.filteredPurchases)return t;
        $scope.filteredPurchases.forEach(function(a,b,c){
            if(!a.excludeFromTotal)
                t+=a.summaries.total;
        });
        return t;
    }
    $scope.changeExcludeTotal = function(){
        $scope.purchases.forEach(function(a,b,c){
            a.excludeFromTotal = $scope.excludeFromTotal;
        });
        
    }
}]);
app.controller('newPurchaseCtrl', ['$scope', '$routeParams', '$location', 'suppliersService', 'purchasesService', 'productsService', function ($scope, $routeParams, $location, suppliersService, purchasesService, productsService) {
    
   
    function newPurchase() {
        $scope.purchase = {
            created: new Date(),
            //expireDate: new Date(),
            paymentType: "online",
            status: "Pagada",
            products : []
        };
    }
    if($routeParams.id) {
        if($location.$$path.indexOf('clone') >= 0)
            $scope.cloning = true;
        else $scope.editing = true;
    }
    else newPurchase();

    suppliersService.get().$promise.then(function(d){
        $scope.suppliers = d;

        if($scope.editing || $scope.cloning)
        {
            purchasesService.get($routeParams).$promise.then(function(d){
                $scope.purchase = d;
                if ($scope.editing){
                    $scope.purchase.id = $routeParams.id;
                    if($scope.purchase.periodStart)
                        $scope.showPeriod = true;
                }
                if ($scope.cloning) {
                    $scope.purchase.id = null;
                    $scope.purchase.number = null;
                    $scope.purchase.status = 'Pagada';
                    $scope.purchase.payed = null;
                    $scope.purchase.created = new Date();
                }
                $scope.fillSummaries();


                if (!$scope.editing) 
                    generateNextNumber();     

            }, function(){
                alert('Ha ocurrido un error al consultar clientes');
            });
        } 
        else {
            if (!$scope.editing) 
                generateNextNumber();            
        }

    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
    function generateNextNumber(){
        //hayar próxima factura...
            purchasesService.get().$promise.then(function(d){
                var maxNumber = _.max(d, function(inv){ return inv.number; }).number;
                if(!maxNumber) 
                    maxNumber = 0;
                $scope.purchase.number = maxNumber + 1;
            }, function(){
                $scope.generateGeneralStatus = 'Ocurrió un error al cargar las facturas. Vuelva a intentarlo';
            }); 
    }
    /*
    productsService.get().$promise.then(function(d){
        $scope.products = [];
        for(var i in d){
            var product = d[i];
            if(!product.ref)return;
            product.id = i;
            $scope.products.push(product);
        }
        
    }, function(){
        alert('Ha ocurrido un error al consultar productos');
    });
    */
    $scope.$watch('purchase.status', function(){
        updatePayed();
    });
    function updatePayed(){
        if($scope.purchase && $scope.purchase.status == 'Pagada' && $scope.summary && $scope.summary.total)
            $scope.purchase.payed =  $scope.summary.total;
    }

    $scope.products = [];
    $scope.fillSummaries = function() {
        $scope.summary = {
           discount: 0,
           summary: 0,
           total: 0
        };
        $scope.summary = $scope.getInvoceSummaries($scope.purchase);
        updatePayed();
    }
    $scope.productChanged = function(p) {
        var fullP = _.where($scope.products, {id : p.productId})[0];
        $.extend(p, fullP);
        
    }
    $scope.remove = function(p){
        if(confirm('Desea eliminar este producto?'))
            $scope.purchase.products = $scope.purchase.products.filter(function(el){
              return el.$$hashKey !== p.$$hashKey;
            });
    }
    $scope.save = function(){
        if($scope.purchase.products.length == 0)
            return alert('No hay productos');
        if($scope.editing) {
            //var i = {};
            //i[$routeParams.id] = $scope.purchase;
            purchasesService.edit($scope.purchase).$promise.then(function(d){
                document.location.href="#!/purchases";
            }, function(){
                alert('Ha ocurrido un error');
            });
        }
        else {
            purchasesService.save($scope.purchase).$promise.then(function(d){
                if(d.name){
                    document.location.href="#!/purchases";
                }
            }, function(){
                alert('Ha ocurrido un error');
            });
        }
    }
}]);
