angular.module('core').factory('authService', ['$q', function ($q) {
  this.user = undefined;

  this.signedDefer = $q.defer();

  const setUser = (user) => {
    this.user = user;
    window['_token'] = user ? user.accessToken : undefined;

    if (user) {
      this.signedDefer.resolve();
    }
  };

  var deferred = $q.defer();

  this.init = (containerId) => {
    var uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      signInFlow: 'popup',
      tosUrl: 'terminos-y-condiciones.html',
      privacyPolicyUrl: function() {
        window.location.assign('http://127.0.0.1:8080');
      },
      callbacks: {
        signInFailure: function (error) {
          console.error('sign in failure', error);
          reject(error);
          return handleUIError(error);
        }
      }
    };
  
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start(containerId, uiConfig);
    if (ui.isPendingRedirect()) {
      ui.start(containerId, uiConfig);
    }
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const newUser = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid
        };
        
        user.getIdToken().then((accessToken) => {
          newUser.accessToken = accessToken;
          
          deferred.resolve(newUser);
        });
      } else {
        deferred.resolve(undefined);
      }
    }, function(error) {
      console.error(error);
      deferred.resolve(undefined);
    });
  };

  deferred.promise.then((user) => {
    setUser(user);
  });

  this.signed = () => {
    return !!this.user;
  };

  this.signOut = () => {
    firebase.auth().signOut();
    setUser(undefined);
  };

  return this;
}]);