app.controller('globalCtrl', ['$scope', 'clientsService', 'authService', function ($scope, clientsService,  authService) {
    $scope.authService = authService;

    $scope.configuration = {
        coin : 'COP',
        additional: 'Cuenta de cobro No° 072 62267 321 Ahorros Bancolombia. Por favor envíe su soporte de pago a egomez@edasystem.com',
        dateFormat: 'yyyy-MMM-dd',
        dateMomentFormat: 'YYYY-MM-DD',
        dateTimeFormat: 'yyyy-MMM-dd HH:mm:ss',
        daysExpireInvoces: 5
    };
    $scope.paymentTypes = {
        'cash' : { code : 'cash', name: 'Dinero en efectivo'},
        'transfer': { code : 'transfer', name: 'Transferencia'},
        'card': { code: 'card', name: 'Tarjeta'},
        'cheque': { code: 'cheque', name: 'Cheque'},
        'online': { code: 'online', name: 'Pago en línea'},
        'paypal': { code: 'paypal', name: 'Paypal'}
    };
    $scope.programPeriods = {
        'month-to-month': { code: 'month-to-month', name: 'Meses', reserved: 'months' },
        'year-to-year': { code: 'year-to-year', name: 'Años', reserved: 'years' }
    };
    $scope.getInvoceSummaries = function(invoce){
        var summary = 0;
        var discount = 0;
        for(var i in invoce.products){
            if(!invoce.products[i])continue;
            var thisDiscount = 
            invoce.products[i].unitPrice * (((invoce.products[i].discount)?invoce.products[i].discount:0) / 100) * invoce.products[i].quantity;
            discount+=thisDiscount;
            summary+=invoce.products[i].unitPrice * invoce.products[i].quantity;
            
        }
        return { 
            discount: discount,
            summary: summary,
            total: summary - discount
        }
    }
    $scope.getDateString = function(d){
        return moment(d).format($scope.configuration.dateMomentFormat);
    }
    // Speed up calls to hasOwnProperty
    var hasOwnProperty = Object.prototype.hasOwnProperty;

    $scope.isEmpty = function(obj) {

        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0)    return false;
        if (obj.length === 0)  return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if(key && key.indexOf('$') == 0)
                continue;
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    }
}]);