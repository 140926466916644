angular.module('app').factory('invocesService', ['$resource', '$q', function ($resource, $q) {
  const resourceInstance = $resource(baseUrl + '/invoces/:id'+'.json', {id: '@id'}, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }}
  });

  const getSummaries = (invoce) => {
    var summary = 0;
    var discount = 0;
    for(var i in invoce.products){
        if(!invoce.products[i])continue;
        var thisDiscount = 
        invoce.products[i].unitPrice * (((invoce.products[i].discount)?invoce.products[i].discount:0) / 100) * invoce.products[i].quantity;
        discount+=thisDiscount;
        summary+=invoce.products[i].unitPrice * invoce.products[i].quantity;
        
    }
    return { 
        discount: discount,
        summary: summary,
        total: summary - discount
    }
  };

  const get = () => {
    const deferred = $q.defer();

    resourceInstance.get().$promise.then((d) => {
      const invoces = [];
      for(let p in d){
          let invoce = d[p];
          if(!invoce.number) continue;
          invoce.summaries = getSummaries(invoce);
          if(invoce.status != 'Pagada' && invoce.expireDate <= new Date())
              invoce.expired=true;
          invoce.invoceId = p;
          
          invoces.push(invoce);
      }
      deferred.resolve(invoces);
    }, function(){
      deferred.reject();
    });

    return deferred.promise;
  };

  return {
    get: get,
    getSummaries: getSummaries,
    resource: resourceInstance
  }
}]);
