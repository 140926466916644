app.controller('personsCtrl', ['$scope', '$routeParams', '$location', 'clientsService', 'suppliersService', function ($scope, $routeParams, $location, clientsService, suppliersService) {
    var localService
    if ($location.$$path.indexOf('suppliers') >= 0){
        $scope.personType = 'suppliers';
         localService = suppliersService;
    }
    else { 
        $scope.personType = 'clients';
        localService = clientsService;
    }
    $scope.viewName = ($scope.personType == 'suppliers') ? 'Proveedores' : 'Clientes';
    $scope.viewNameSingular = ($scope.personType == 'suppliers') ? 'Proveedor' : 'Cliente';


    function load(){
        $scope.expiredClients = {};
        localService.get().$promise.then(function(d){
            for(var i in d)
            {
                if(d[i].payedUntil){

                    if(d[i].payedUntil.getTime() <= moment().add(5, 'days').toDate().getTime())
                        $scope.expiredClients[i] = 'coming-soon';
                    if(d[i].payedUntil.getTime() < moment().add(1, 'days').toDate().getTime())
                        $scope.expiredClients[i] = 'expired';
                    d[i].fromNow = moment(d[i].payedUntil).fromNow();
                }
            }
            $scope.clients = d;
        }, function(){
            alert('Ha ocurrido un error');
        });
    }
    load();
    $scope.addContact = function() {
        if (!$scope.client)
            $scope.client = {};
        if(!$scope.client.contacts)
            $scope.client.contacts = []; 
        $scope.client.contacts.push({});
    }
    $scope.remove = function(p, index){
        if(confirm('Desea eliminar este contacto?'))
            $scope.client.contacts.splice(index, 1);
    }
    $scope.save = function(){
        if($scope.editing) {            
            localService.edit($scope.client).$promise.then(function(d){
                load();
                $scope.client = null;
                $scope.editing=false;
            }, function(){
                alert('Ha ocurrido un error');
            }); 
        }
        else{
            localService.save($scope.client).$promise.then(function(d){
                if(d.name){
                    load();
                    $scope.client = null;
                }
            }, function(){
                alert('Ha ocurrido un error');
            }); 
        }
    }
    $scope.edit = function(id, c) {
        $scope.client = $.extend({},c);
        $scope.client.id = id;
        $scope.editing = true;
    }
}]);