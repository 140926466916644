angular.module('app').factory('clients2Service', ['$resource', '$q', function ($resource, $q) {
  const resourresourceInstancece = $resource(baseUrl + '/clients/:id'+'.json', {id: '@id'}, {
      get: { method: 'GET', params: { includeToken: true }},
      save: { method: 'POST', params: { includeToken: true }},
      edit: { method: 'PUT', params: { includeToken: true }}
  });

  const get = () => {
    const deferred = $q.defer();
    resourresourceInstancece.get().$promise.then(d => {
      const clients = [];
      for (var i in d) {
        if (d[i].name) {
          d[i].id = i;
          clients.push(d[i]);
        }
      }
      deferred.resolve(clients);
    }, function() {
      deferred.reject();
    });
    return deferred.promise;
  };

  return {
    get: get
  }
}]);