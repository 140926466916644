angular.module('app').controller('printCtrl', ['$scope', '$routeParams', 'invocesService', 'clientsService', 'authService', function ($scope, $routeParams, invocesService, clientsService, authService) {
  const loadInvoce = () => {
    invocesService.resource.get({id: QueryString.invoceid}).$promise.then(function(d){
      d.summary = $scope.getInvoceSummaries(d);
      $scope.invoce = d;
  
      clientsService.get({id: d.clientId}).$promise.then(function(d){
        $scope.client = d;
        var numstr = $scope.invoce.number.toString();
        while(numstr.length < 4)
          numstr='0'+numstr;
        document.title= numstr +  '_'+(($scope.invoce.requirementName) ? $scope.invoce.requirementName+'_':'')+ d.name;
        console.log(d);
      }, function(){
          alert('Ha ocurrido un error');
      });
    }, function(){
        alert('Ha ocurrido un error');
    });
  };

  authService.signedDefer.promise.then(loadInvoce);
}]);
