angular.module('app').controller('invocesCtrl', ['$scope', 'clientsService', 'invocesService', 'productsService', function ($scope, clientsService, invocesService, productsService) {
    
    clientsService.get().$promise.then(function(d){
        for(var i in d){
            d[i].id = i;
        }
        $scope.clients = d;

    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
    invocesService.get().then(function(d){
        $scope.invoces = d;
    }, function(){
        alert('Ha ocurrido un error al consultar clientes');
    });
    $scope.getFilterTotal = function(){
        var t = 0;
        if(!$scope.filteredInvoces)return t;
        $scope.filteredInvoces.forEach(function(a,b,c){
            if(!a.excludeFromTotal)
                t+=a.summaries.total;
        });
        return t;
    }
    $scope.changeExcludeTotal = function(){
        $scope.invoces.forEach(function(a,b,c){
            a.excludeFromTotal = $scope.excludeFromTotal;
        });
        
    }
}]);

